import axios from "axios"
import { endpoints } from "../_config"

class UserService {

    /**
     * Update user profile data
     * @param {*} user 
     * @param {*} data 
     * @returns 
     */
    static async updateProfile(user, data) {
        return axios.post(endpoints.user.updateProfile, data, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    /**
     * Update user picture
     * @param {*} user 
     * @param {*} picture 
     */
    static async updatePicture(user, picture) {
        const data = new FormData();
        // add picture
        data.append("picture", picture, picture.fileName);
        // call api
        return axios.post(endpoints.user.updatePicture, data, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    /**
     * sync one signal userid
     * @param {*} user 
     * @returns 
     */
    static async syncOneSignalWebUserId(user, userId) {
        return axios.post(endpoints.user.syncOneSignalWebUserId, { userId }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    static async getUserProfile(user) {
        console.log("here", process.env);
        return axios.get(endpoints.user.profile, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    /**
     * Get profile of a user by id
     * @param {*} user 
     * @param {*} userId 
     * @returns 
     */
    static async getUserProfileById(user, userId) {
        return axios.get(endpoints.user.profileById(userId), {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    /**
     * Get all users
     * @param {*} user 
     * @returns 
     */
    static async getAllUsers(user) {
        return axios.get(endpoints.user.getAllUsers, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    static async followUser(user, userId) {
        return axios.get(endpoints.user.followUser(userId), {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);

    }
}

export { UserService }